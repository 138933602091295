html {
  font-size: 16px;
}

@media (width >= 1024px) and (width <= 1360px) {
  html {
    font-size: 10px;
  }
}

@media (width >= 1360px) and (width <= 1800px) {
  html {
    font-size: 12px;
  }
}

@media (width >= 1024px) {
  .scrollbar {
    scrollbar-width: thin;
  }
}
/*# sourceMappingURL=index.aebfbdcf.css.map */
