html {
	font-size: 16px;
}

@media (min-width: 1024px) and (max-width: 1360px) {
	html {
		font-size: 10px;
	}
}

@media (min-width: 1360px) and (max-width: 1800px) {
	html {
		font-size: 12px;
	}
}

@media (min-width: 1024px) {
	.scrollbar {
		scrollbar-width: thin;
	}
}
